<template>
  <UModal id="hub-confirmation-modal-card">
    <div class="flex justify-between">
      <h3 class="mb-4 ml-6 mt-6 flex items-center justify-start text-base font-semibold text-purple dark:text-white">
        <UIcon
          :name="icon"
          class="mr-2 !size-5"
        />
        {{ useTitleTranslation ? $t(title) : title }}
      </h3>
    </div>

    <UDivider class="px-5" />
    <div class="p-6 text-sm text-grey-blue">
      <p class="mb-4">
        {{ useWarningTranslation ? $t(warning) : warning }}
      </p>
      <p>{{ useAreYouSureTranslation ? $t(areYouSure) : areYouSure }}</p>
    </div>
    <UDivider class="px-5" />

    <slot name="footer">
      <div class="flex items-center justify-end px-6 py-4">
        <HubButton
          :type="cancelButtonType"
          :color="cancelButtonColor"
          size="xs"
          class="mr-2"
          :label="$t(cancelText)"
          @click="cancel"
        />
        <HubButton
          v-if="showConfirmButton"
          :type="confirmButtonType"
          :color="confirmButtonColor"
          :label="$t(confirmText)"
          size="xs"
          v-bind="confirmButtonAttrs"
          @click="confirmClick"
        />
      </div>
    </slot>
  </UModal>
</template>

<script setup lang="ts">
import type { HubButtonType, HubButtonColours } from './../HubButton.vue'

const props = withDefaults(
  defineProps<{
    icon?: string
    title?: string
    useTitleTranslation?: boolean
    warning?: string
    useWarningTranslation?: boolean
    areYouSure?: string
    useAreYouSureTranslation?: boolean
    confirmText?: string
    confirmButtonType?: HubButtonType
    cancelText?: string
    cancelButtonType?: HubButtonType
    confirmButtonColor?: HubButtonColours
    confirmButtonAttrs?: object
    cancelButtonColor?: HubButtonColours
    showConfirmButton?: boolean
    translateTitle?: boolean
    headerBorder?: boolean
    footerBorder?: boolean
    contentPadding?: boolean
    preventCloseOnConfirm?: boolean
  }>(),
  {
    icon: 'i-mdi-trash-can-outline',
    title: 'confirmation.delete.title',
    useTitleTranslation: true,
    warning: 'confirmation.delete.warning',
    useWarningTranslation: true,
    areYouSure: 'confirmation.delete.areYouSure',
    useAreYouSureTranslation: true,
    confirmText: 'confirmation.delete.confirm',
    cancelText: 'confirmation.delete.cancel',
    translateTitle: true,
    headerBorder: true,
    footerBorder: true,
    contentPadding: true,
    cancelButtonType: 'outlined',
    confirmButtonAttrs: undefined,
    confirmButtonType: undefined,
    confirmButtonColor: 'danger',
    cancelButtonColor: 'default',
    showConfirmButton: true,
    preventCloseOnConfirm: false
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', arg1: boolean): void
  (e: 'close' | 'confirm' | 'cancel'): void
}>()

function confirmClick() {
  if (!props.preventCloseOnConfirm) {
    emit('update:modelValue', false)
    emit('close')
  }
  emit('confirm')
}

function cancel() {
  emit('update:modelValue', false)
  emit('close')
  emit('cancel')
}
</script>
